import React from "react"
import {
  // BrowserRouter,
  HashRouter,
  Route,
  Switch,
  Redirect, Router
} from "react-router-dom"
import { createBrowserHistory } from "history";

import PrivateRoute from "./components/privateRoute/loadable"
import NotFoundPage from "./views/notFoundPage/loadable"

import AdminMainLayout from "./layouts/mainLayout"
// import HomePage from './containers/homePage/index'

// import UserManagementLayout from './layout/userManagementLayout'
import AdminManagementPage from "./containers/adminManagementPage"

// import MemberManagementPage from './containers/memberManagementPage'
import MemberManagementLayout from "./layouts/memberManagementLayout"
import MemberCustomerPage from "./containers/memberCustomerPage"

import maidLayout from "./layouts/maidLayout"
import maidManagementLayout from "./layouts/maidManagementLayout"
import MemberMaidPage from "./containers/memberMaidPage"
import MaidMonthlyPage from "./containers/maidMonthlyPage"
import maidProfile from "./containers/maidProfile"
// import MemberMaidHardPage from './containers/memberMaidPage/indexHard'

import inventoriesLayout from "./layouts/inventoriesLayout"
import InvencalendarInventory from "./containers/InvencalendarInventory"

import approveLayot from "./layouts/approveLayout/index"
import approvemanagementLayout from "./layouts/approvemanagementLayout/index"
import OrderManagementApprove from "./containers/orderManagementApprove/index"

import paymentLayout from "./layouts/paymentLayout/index"
import paymentManagementLayout from "./layouts/paymentManagementLayout/index"
import orderManagementPayment from "./containers/orderManagementPayment/index"
import orderManagementFilter from "./containers/orderManagementFilter/index"

import orderLayout from "./layouts/orderLayout/index"
import orderManagementLayout from "./layouts/orderManagementLayout/index"
import OrderManagementBooking from "./containers/orderManagementBooking/index"
import orderBookingProfile from "./containers/orderBookingProfile/index"

import ContentManagementLayout from "./layouts/contentManagementLayout"
import ContentPublishedPage from "./containers/contentPublishedPage"

import broadcastLayout from "./layouts/broadcastLayout/index"
import broadcastManagementLayout from "./layouts/broadcastManagementLayout"
import broadcastSent from "./containers/broadcastSent/index"
import broadcastError from "./containers/broadcastError/index"
import broadcastTemplate from "./containers/broadcastTemplate/index"
import broadcastAnnounceTemplate from "./containers/broadcastAnnounceTemplate/index"

import promotionLayout from "./layouts/promotionLayout/index"
import promotionManagementLayout from "./layouts/promotionManagementLayout/index"
import promotionAdd from "./containers/promotionAdd/index"
import promotionInactive from "./containers/promotionInactive/index"
import PromotionActived from "./containers/promotionActived/index"
import promotionExpired from "./containers/promotionExpired/index"

import ChatBoxPage from "./containers/chatBoxPage"
import DashboardPage from "./containers/dashboardPage"

import DashboardLayout from "./layouts/dashboardLayout"
import DashboardOverallPage from "./containers/dashboardOverallPage"
import DashboardCustomerPage from "./containers/dashboardCustomerPage"

import DatabaseLayout from "./layouts/databaseLayout"
import DatabaseMaidPage from "./containers/databaseMaidPage"

// import SettingLayout from './layouts/settingLayout'
import SettingPage from "./containers/settingPage"
import SettingProfilePage from "./containers/settingProfilePage"
import SettingRolesPage from "./containers/settingRolesPage"

import SettingGroupLayout from "./layouts/settingGroupLayout/index"
import SettingGroupPage from "./containers/settingGroupPage/index"
import SettingGroupOfUser from "./containers/settingGroupOfUser/index"
import settingGroupCustomer from "./containers/settingGroupOfUserCustomer/index"
import settingGroupMaid from "./containers/settingGroupOfUsermaid/index"
import loginPage from "./containers/loginPage"
import DummyWorkPage from "./containers/dummyWork";
import dummyLayout from "./layouts/dummyLayout";

// function BlogPost() {
//   // We can call useParams() here to get the params,
//   // or in any child element as well!
//   let { id } = useParams()
//   // ...
//   // console.log(id )
// }
const history = createBrowserHistory();
const adminRoutes = {
  path: "/admin",
  layout: AdminMainLayout,
  routes: [
    // ##### Here is Redirect Pattern Route #####
    // {
    //   exact: true,
    //   subpath: '/',
    //   render: ({ match }) => <Redirect to={`${match.url}/login`} />,
    // },
    {
      // ##### Here is set to Lannding ... Can be set to another page for testing ######
      exact: true,
      subpath: "/",
      render: ({ match }) => <Redirect to={`${match.url}/customer-management`} />,
      // component: HomePage,
    },
    { isPrivate: false, exact: true, subpath: "/login", component: loginPage },
    {
      isPrivate: true,
      exact: true,
      subpath: "/admin-management",
      component: AdminManagementPage,
    },

    {
      path: "/admin/customer-management",
      layout: MemberManagementLayout,
      routes: [
        {
          exact: true,
          subpath: "/",

          render: ({ match }) => <Redirect to={`${match.url}/customer`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/customer",
          component: MemberCustomerPage,
        },
        {
          exact: true,
          subpath: "/2",

          render: ({ match }) => <Redirect to={`${match.url}/customer2`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/2/customer2",
          component: MemberCustomerPage,
        },
      ],
    },
    {
      path: "/admin/maid-management",
      layout: maidLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          isPrivate: true,
          subpath: "/dummy_work",
          component: DummyWorkPage
        },
        {
          path: "/admin/maid-management/list",
          layout: maidManagementLayout,
          routes: [
            {
              exact: true,
              subpath: "/",
              render: ({ match }) => <Redirect to={`${match.url}/maid`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/maid",
              component: MemberMaidPage,
            },

          ],
        },
        {
          exact: true,
          subpath: "/radius",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/radius/list",
          component: MemberMaidPage,
        },
        {
          exact: true,
          subpath: "/monthly",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/monthly/list",
          component: MaidMonthlyPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/maidProfile/:id",
          component: maidProfile,
        },
      ],
    },
    //
    {
      path: "/admin/Approve-management",
      layout: approveLayot,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          path: "/admin/Approve-management/list",
          layout: approvemanagementLayout,
          routes: [
            {
              exact: true,
              subpath: "/",
              render: ({ match }) => <Redirect to={`${match.url}/Approve`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/Approve",
              component: OrderManagementApprove,
            },
          ],
        },
      ],
    },
    {
      path: "/admin/dummy-management",
      layout: dummyLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          isPrivate: true,
          path: "/admin/dummy-management/list",
          component: DummyWorkPage
        }
      ]
    },

    {
      path: "/admin/payment-management",
      layout: paymentLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          path: "/admin/payment-management/list",
          layout: paymentManagementLayout,
          routes: [
            {
              exact: true,
              subpath: "/",
              render: ({ match }) => <Redirect to={`${match.url}/Payment`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/Payment",
              component: orderManagementPayment,
            },
          ],
        },
        {
          isPrivate: true,

          exact: true,
          subpath: "/orderManagementFilter/:id/:start_datetime/:end_datetime",
          component: orderManagementFilter,
        },
      ],
    },

    {
      path: "/admin/inventories-management",
      layout: inventoriesLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/inventories`} />,
        },
        {
          isPrivate: true,

          exact: true,
          subpath: "/inventories",
          component: InvencalendarInventory,
        },
      ],
    },
    {
      //
      //
      path: "/admin/order-management",
      layout: orderLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          path: "/admin/order-management/list",
          layout: orderManagementLayout,
          routes: [
            {
              exact: true,
              subpath: "/",
              render: ({ match }) => <Redirect to={`${match.url}/Booking`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/Booking",
              component: OrderManagementBooking,
            },
            {
              exact: true,
              subpath: "/2",
              render: ({ match }) => <Redirect to={`${match.url}/Booking2`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/2/Booking2",
              component: OrderManagementBooking,
            },
          ],
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/orderProfileBk/:id/:bokingId",
          component: orderBookingProfile,
        },
      ],
    },

    {
      path: "/admin/content",
      layout: ContentManagementLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/published`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/published",
          component: ContentPublishedPage,
        },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      subpath: "/polls-forms",
      component: SettingPage,
    },
    {
      path: "/admin/broadcasting",
      layout: broadcastLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          path: "/admin/broadcasting/list",
          layout: broadcastManagementLayout,
          routes: [
            {
              exact: true,
              subpath: "/",
              render: ({ match }) => <Redirect to={`${match.url}/broadcastSent`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/broadcastSent",
              component: broadcastSent,
            },
            {
              isPrivate: false,
              exact: true,
              subpath: "/broadcastError",
              component: broadcastError,
            },
          ],
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/announceTemplate",
          component: broadcastAnnounceTemplate,
        },
        {
          isPrivate: false,
          exact: true,
          subpath: "/broadcastTemplate",
          component: broadcastTemplate,
        },
      ],
    },
    {
      // promotionManagementLayout
      path: "/admin/promotion",
      layout: promotionLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/list`} />,
        },
        {
          path: "/admin/promotion/list",
          layout: promotionManagementLayout,
          routes: [
            {
              exact: true,
              subpath: "/",
              render: ({ match }) => <Redirect to={`${match.url}/promotionInactive`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/promotionInactive",
              component: promotionInactive,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/promotionActived",
              component: PromotionActived,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/promotionExpired",
              component: promotionExpired,
            },
          ],
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/promotionAdd",
          component: promotionAdd,
        },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      subpath: "/chatbox",
      component: ChatBoxPage,
    },
    {
      isPrivate: true,
      exact: true,
      subpath: "/chatbox/:id/:role/:chanel",
      component: ChatBoxPage,
    },
    {
      isPrivate: true,
      exact: true,
      subpath: "/dashboard0",
      component: DashboardPage,
    },
    // {
    //   path: '/admin/dashboard',
    //   layout: DashboardLayout,
    //   routes: [
    //     {
    //       exact: true,
    //       subpath: '/',
    //       render: ({ match }) => <Redirect to={`${match.url}/overall`} />,
    //     },

    //   ],
    // },
    {
      path: "/admin/dashboard",
      layout: DashboardLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/overall`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/overall",
          component: DashboardOverallPage,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/customer",
          component: DashboardCustomerPage,
        },
        // {
        //   isPrivate: false,
        //   exact: true,
        //   subpath: '/maid',
        //   component: DashboardMaidPage,
        // },
      ],
    },
    {
      path: "/admin/database",
      layout: DatabaseLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/maid`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/maid",
          component: DatabaseMaidPage,
        },
      ],
    },
    {
      isPrivate: true,
      exact: true,
      subpath: "/profile-setting",
      component: SettingProfilePage,
    },
    {
      isPrivate: true,
      exact: true,
      subpath: "/roles-setting",
      component: SettingRolesPage,
    },

    ///////////////////
    //////

    {
      path: "/admin/group-setting",
      layout: SettingGroupLayout,
      routes: [
        {
          exact: true,
          subpath: "/",
          render: ({ match }) => <Redirect to={`${match.url}/setting-group`} />,
        },
        {
          isPrivate: true,
          exact: true,
          subpath: "/setting-group",
          component: SettingGroupPage,
        },

        {
          path: "/admin/group-setting/setting-group-Of-user",
          layout: SettingGroupOfUser,
          routes: [
            {
              exact: true,
              subpath: "/",
              render: ({ match }) => <Redirect to={`${match.url}/setting-group-customer`} />,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/setting-group-customer",
              component: settingGroupCustomer,
            },
            {
              isPrivate: true,
              exact: true,
              subpath: "/setting-group-maid",
              component: settingGroupMaid,
            },
          ],
        },
      ],
    },
  ],
}

// Guide Line for all route
// guestRoute / unAuthenthicationRoute [ Login, Authentication, Forgot Password]
// adminRoute       [ All feature ]
// adminCustomRoute [ May have config file to custom route from SuperAdmin permission given]

const rootRoutes = [
  {
    exact: true,
    path: "/",
    render: () => <Redirect to="/admin" />,
  },
  adminRoutes,
]

const generateRoutes = (routes, parentProps) => {
  return (
    <Switch>
      {routes.map(({ routes: childRoutes, layout: Layout, subpath, isPrivate, ...props }) => {
        const CrossRoutes = isPrivate ? PrivateRoute : Route
        const path = subpath ? `${parentProps.path}${subpath}` : props.path
        if (childRoutes) {
          return (
            <CrossRoutes
              key={JSON.stringify(routes)}
              {...props}
              render={(layoutProps) => (
                <Layout {...layoutProps}>{generateRoutes(childRoutes, props)}</Layout>
              )}
            />
          )
        }
        //console.log(`process.env.PUBLIC_URL : ${process.env.PUBLIC_URL + path}`)
        // return <CrossRoutes key={JSON.stringify(routes)} {...props} path={process.env.PUBLIC_URL + path} />
        return <CrossRoutes key={JSON.stringify(routes)} {...props} path={path} />
      })}
      <PrivateRoute path="**" component={NotFoundPage} />
    </Switch>
  )
}
// {/* <BrowserRouter basename="/proj-bluuu-admin-frontend"></BrowserRouter> */}

const Routes = () => {
  return <HashRouter history={history}>{generateRoutes(rootRoutes)}</HashRouter>
}

export default Routes
