import request from "../../utils/request"

export const maid = {
  state: {
    maidsLsit: 0,
    column: [
      { label: "Picture", value: 1, accessor: "picture", isShow: false },
      // { label: "App ID", value: 1, accessor: "App_ID", isShow: true },
      { label: "Maid ID", value: 1, accessor: "Maid_ID", isShow: true },
      { label: "Maid Name", value: 2, accessor: "name", isShow: true },
      { label: "Maid Phone", value: 4, accessor: "phone", isShow: true },
      { label: "Maid Tier", value: 1, accessor: "Maid_Tier", isShow: true },
      { label: "Average Ratings", value: 1, accessor: "Average_Ratings", isShow: true },
      { label: "# of ratings", value: 1, accessor: "of_ratings", isShow: true },
      { label: "Repeat Rate", value: 1, accessor: "Repeat_Rate", isShow: true },
      { label: "Total OTA", value: 1, accessor: "Total_OTA", isShow: true },
      { label: "Net OTA", value: 1, accessor: "Net_OTA", isShow: true },
      { label: "% OTA", value: 1, accessor: "Percent_OTA", isShow: true },
      { label: "Start Date", value: 1, accessor: "Start_Date", isShow: true },
      { label: "District of Pin", value: 1, accessor: "District_of_Pin", isShow: true },
      { label: "Radius", value: 1, accessor: "Radius", isShow: true },
      { label: "Lock_Radius", value: 1, accessor: "Lock_Radius", isShow: true },
      { label: "Interviewer", value: 1, accessor: "Interviewer", isShow: true },
      { label: "Trainer", value: 1, accessor: "Trainer", isShow: true },
    ],
    columnFilter: [
      { label: "Average Ratings", value: 1, accessor: "Average_Ratings", isShow: true },
      { label: "Repeat Rate", value: 1, accessor: "Repeat_Rate", isShow: true },
      { label: "Published", value: 1, accessor: "Is_Published", isShow: true },
    ],
    columnMaidProfile: [
      { label: "Cumulative", accessor: "Cumulative", isShow: true },
      { label: "Mounth", accessor: "Mounth", isShow: true },
      { label: "Work Completed", accessor: "Work_completed", isShow: true },
      { label: "Hrs Completed", accessor: "Hrs_completed", isShow: true },
      { label: "Salary(THB)", accessor: "salary", isShow: true },
      { label: "5 Star Bonus", accessor: "star_bonus", isShow: true },
      { label: "Monthly Bonus", accessor: "Monthly_bonus", isShow: true },
      { label: "Milestone Bonus", accessor: "Milestone_bonus", isShow: true },
    ],
    textSearch: "",
    totalPageList: 0,
    averageListFilter: [
      { id: 1, label: "4.85 and Above" },
      { id: 2, label: "4.70 To 4.85" },
      { id: 3, label: "4.55 To 4.70" },
      { id: 4, label: "4.10 To 4.55" },
      { id: 5, label: "3.95 To 4.10" },
      { id: 6, label: "Below 3.95" },
    ],
    averageCheckBox: [
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
    ],
    repeatRateListFilter: [
      { id: 1, label: "90% and Above" },
      { id: 2, label: "80% To 90%" },
      { id: 3, label: "70% To 80%" },
      { id: 4, label: "60% To 70%" },
      { id: 5, label: "50% To 60%" },
      { id: 6, label: "40% To 50%" },
      { id: 7, label: "30% To 40%" },
      { id: 8, label: "20% To 30%" },
      { id: 9, label: "10% To 20%" },
      { id: 10, label: "Below 10%" },
    ],
    repeatRateCheckBox: [
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
      { checked: true },
    ],
    publishedListFilter: [
      { id: 1, label: "Published Maid Only" },
    ],
    publishedCheckBox: [
      { checked: false },
    ],
  },
  reducers: {
    setDataSearch(state, payload) {
      return { ...state, textSearch: payload }
    },
    setMaidList(state, payload) {
      return { ...state, maidsLsit: payload }
    },
    setTotalPage(state, payload) {
      return { ...state, totalPageList: payload }
    },
    setMaidColumnList(state, payload) {
      // console.log(`payload: ${JSON.stringify(payload)}`)
      return { ...state, column: payload }
    },
    toggleShowMaidColumn(state, payload) {
      // console.log(`payload: ${payload}`)
      // console.log(`target: ${JSON.stringify(this.state)}`)
      return { ...state, column: { ...state.column } }
    },
    setAverageListFilter(state, payload) {
      return { ...state, averageListFilter: payload }
    },
    averageCheckBoxDis(state, payload) {
      window.localStorage.setItem("averageCheckBox", JSON.stringify(payload))
      return { ...state, averageCheckBox: payload }
    },
    setRepeatRateListFilter(state, payload) {
      return { ...state, repeatRateListFilter: payload }
    },
    repeatRateCheckBoxDis(state, payload) {
      window.localStorage.setItem("repeatRateCheckBox", JSON.stringify(payload))
      return { ...state, repeatRateCheckBox: payload }
    },
    setPublishedListFilter(state, payload) {
      return { ...state, publishedListFilter: payload }
    },
    publishedCheckBoxDis(state, payload) {
      window.localStorage.setItem("publishedCheckBox", JSON.stringify(payload))
      return { ...state, publishedCheckBox: payload }
    },
  },
  effects: (dispatch) => ({
    async getMaidList(txtSearch) {
      const res = await request.get(`maid/list?search=${txtSearch}&limit=5000`)
      const cleanData = res.data.item_count
      const totalPage = Math.ceil(res.data.item_count / 5000)
      dispatch.maid.setMaidList(cleanData)
      dispatch.maid.setTotalPage(totalPage)
    },
  }),
}
